import {publishEvent} from './services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from './services/analytics/pub-sub/constants';

document.addEventListener('DOMContentLoaded', () => {
  const feedbackButtonNode = document.querySelector('.bottom-nav__buttons-link-feedback');
  const advertButtonNode = document.querySelector('.bottom-nav__buttons-link-advert');

  if (feedbackButtonNode) {
    feedbackButtonNode.addEventListener('click', () => {
      publishEvent(ANALYTIC_EVENTS.FOOTER_FEEDBACK_BUTTON_CLICK, ({
        section: 'footer',
        text: feedbackButtonNode.innerText
      }));
    });
  }

  if (advertButtonNode) {
    advertButtonNode.addEventListener('click', () => {
      publishEvent(ANALYTIC_EVENTS.FOOTER_ADVERT_BUTTON_CLICK, ({
        section: 'footer',
        text: advertButtonNode.innerText
      }));
    });
  }
});
